import { useCallback, useState } from 'react';

import clsx from 'clsx';
import { useEffectOnce } from 'usehooks-ts';

import adminAPI from '@/api/admin';
import Input from '@/components/Input';
import InputSelect from '@/components/InputSelect';
import Modal from '@/components/Modals/Modal';
import MESSAGES from '@/constants/messages-en';
import { getProducts } from '@/helpers/products';
import { Product, Variant } from '@/types/IProduct';
import ISalesChannel from '@/types/ISalesChannel';

import { BUTTON_KIND } from '../Button';

interface ModalState {
  selectedProduct: Product;
  variants: Variant[];
  selectedVariant: Variant;
}

interface ICreateProductsModalProps {
  onRequest: () => void;
  onSuccess: () => void;
  onError: () => void;
}

export default function CreateProductsModal({
  onRequest,
  onSuccess,
  onError,
}: ICreateProductsModalProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const products = getProducts();

  const defaultProduct = products[0];
  const [modalState, setModalState] = useState<ModalState>({
    selectedProduct: defaultProduct,
    variants: defaultProduct.variants,
    selectedVariant: defaultProduct.variants[0],
  });
  const [quantity, setQuantity] = useState('1');
  const [salesChannels, setSalesChannels] = useState<ISalesChannel[]>();
  const [salesChannel, setSalesChannel] = useState<string>('');

  const handleSelectProduct = (name: string) => {
    const product =
      products.find(product => product.name === name) || defaultProduct;

    setModalState({
      ...modalState,
      selectedProduct: product,
      variants: product.variants,
      selectedVariant: product.variants[0],
    });
  };

  const handleSelectVariant = (value: string) => {
    const foundVariant = modalState.variants.find(
      variant => variant.value === value,
    );

    setModalState({
      ...modalState,
      selectedVariant: foundVariant || modalState.variants[0], // Use first variant as default
    });
  };

  const handleCreateCards = async () => {
    setIsLoading(true);
    onRequest();
    adminAPI
      .createCards({
        count: Number(quantity),
        card: {
          type: modalState.selectedVariant.value,
          sales_channel_id: Number(salesChannel),
        },
      })
      .then(() => onSuccess())
      .catch(() => onError())
      .then(() => setIsLoading(false))
      .finally(() => setIsOpen(false));
  };

  const listSalesChannels = useCallback(async (): Promise<{
    data: ISalesChannel[];
  }> => {
    const res = await adminAPI.listSalesChannels();

    return res.data;
  }, []);

  useEffectOnce(() => {
    listSalesChannels().then(res => {
      if (res.data && res.data.length > 0) {
        setSalesChannels(res.data);
        setSalesChannel(String(res.data[0].id));
      }
    });
  });

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      buttonTitle="Create products"
      buttonKind={BUTTON_KIND.PRIMARY}
      dialogTitle={MESSAGES.card.create.heading}
      successButtonText="Create"
      onSuccess={() => handleCreateCards()}
      isLoading={isLoading}
    >
      <div className="mt-6 space-y-4">
        <Input
          label="Quantity"
          value={quantity}
          onChange={setQuantity}
          type="text"
        />
        <InputSelect
          id="product-type"
          label="Product type"
          options={products.map(product => ({
            label: product.name,
            value: product.name,
          }))}
          value={modalState.selectedProduct.name}
          onChange={handleSelectProduct}
        />
        <div
          className={clsx(
            'transition-all duration-300 ease-in-out',
            modalState.variants.length > 1 ? 'show' : 'hidden',
          )}
        >
          <InputSelect
            id="variant-type"
            label="Colour"
            options={modalState.variants.map(variant => ({
              label: variant.name,
              value: variant.value,
            }))}
            value={modalState.selectedVariant.value}
            onChange={handleSelectVariant}
          />
        </div>
        {salesChannels && salesChannels.length > 0 && (
          <InputSelect
            id="sales-channel"
            label="Sales channel"
            options={salesChannels.map(({ id, name }) => ({
              label: name,
              value: String(id),
            }))}
            value={salesChannel}
            onChange={setSalesChannel}
          />
        )}
      </div>
    </Modal>
  );
}
