import { Product, VariantValue } from "@/types/IProduct";

export const products: Product[] = [
  { name: 'Tapt Custom', variants: [{ name: 'Custom', value: 'custom' }] },
  { name: 'Tapt Black', variants: [{ name: 'Black', value: 'black' }] },
  {
    name: 'Tapt Lite',
    variants: [
      { name: 'Bamboo', value: 'lite_bamboo' },
      { name: 'Black', value: 'lite' },
      { name: 'White', value: 'lite_white' },
      { name: 'Blue', value: 'lite_blue' },
      { name: 'Green', value: 'lite_green' },
      { name: 'Purple', value: 'lite_purple' },
    ],
  },
  {
    name: 'Tapt Mini',
    variants: [
      { name: 'Black', value: 'mini_black' },
      { name: 'White', value: 'mini_white' },
    ],
  },
  {
    name: 'Tapt Wallet',
    variants: [
      { name: 'Black', value: 'wallet_black' },
      { name: 'Blue', value: 'wallet_blue' },
      { name: 'Brown', value: 'wallet_brown' },
    ],
  },
  {
    name: 'Tapt Stand',
    variants: [
      { name: 'Black', value: 'stand_black' },
      { name: 'White', value: 'stand_white' },
    ],
  },
  {
    name: 'Tapt Mobile',
    variants: [
      { name: 'Black', value: 'mobile_black' },
      { name: 'White', value: 'mobile_white' },
    ],
  },
];

export const variantsFullName = products.flatMap(product => {
  if (product.variants.length === 1) {
    return {
      value: product.variants[0].value,
      fullName: product.name,
    };
  }

  return product.variants.map(variant => {
    return {
      value: variant.value,
      fullName: `${product.name} - ${variant.name}`,
    };
  })
}).reduce((acc, curr) => {
  acc[curr.value] = curr.fullName;
  return acc;
}, {} as Record<VariantValue, string>);

export const getProducts = () => products;
export const getVariantFullName = (value: VariantValue) => variantsFullName[value];